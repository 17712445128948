<template>
  <router-link
    v-slot="{ href, navigate }"
    :to="to"
  >
    <a
      :href="href"
      class="custom-link-button"
      @click="navigate"
    >
      <span class="button-left">
        <img
          v-if="iconImage"
          :src="require('@/assets/' + iconImage)"
          alt=""
          class="icon"
        >
        <span class="responses-action-text">
          {{ buttonText }}
        </span>
      </span>
      <span
        v-if="displayArrow"
        class="icon-arrow"
      />
    </a>
  </router-link>
</template>

<script>
export default {
  name: 'CustomLinkButton',
  props: {
    buttonText: {
      type: String,
      required: true
    },
    displayArrow: {
      type: Boolean,
      default: false
    },
    iconImage: {
      type: String,
      default: null
    },
    to: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-link-button {
  display: block;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 46px;
  display: flex;
  height: 50px;
  align-items: center;
  padding: 0 12px;
  color: #000;
  font-size: 14px;
  justify-content: space-between;
  transition: $buttonBackgroundTransition;
  min-width: 160px;

  &:hover {
    border-color: #bbb;
  }

  .button-left {
    display: flex;
    align-items: center;
  }

  .icon {
    max-width: 24px;
    margin: 0 12px 0 6px;
  }

  .icon-arrow {
    display: block;
    width: 12px;
    height: 12px;
    background: url("../assets/project/icon-arrow-rigth-blue.svg") center no-repeat;
    margin-left: 20px;
  }
}
</style>
