<template>
  <div class="spinner">
    <div /><div />
  </div>
</template>

<script>
export default {
  name: 'Spinner'
}
</script>

<style lang="scss" scoped>
.spinner {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid $blue;
  border-radius: 50%;
  animation: spinner 1.2s linear infinite;
  border-color: $blue transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.16s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
