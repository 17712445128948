<template>
  <div class="page-bottom-padding">
    <div
      v-for="activity in activities"
      :key="activity.id"
    >
      <activity-item :activity="activity" />
    </div>
  </div>
</template>

<script>
import ActivityItem from './ActivityItem.vue'

export default {
  name: 'ActivityListing',
  components: {
    ActivityItem
  },
  props: {
    activities: {
      type: Array,
      required: true
    }
  }
}
</script>
