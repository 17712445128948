<template>
  <div :class="containerClass">
    <div class="add-comment-container">
      <div class="border">
        <!-- <div class="icon" /> -->
        <div class="text">
          <quill-editor
            ref="quillEditor"
            name="comment"
            :options="quillOptions"
            @keydown.native.enter.exact="submitComment"
            @change="onQuillEditorChange"
          />
        </div>
        <div class="button-container">
          <b-button
            :class="buttonClass"
            :disabled="!dataIsValid"
            :loading="isSavingComment"
            @click="submitComment"
          >
            <span>Comentar</span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'AddCommentBar',
  components: { quillEditor },
  props: {
    commentedContentIdentifier: {
      type: String,
      required: true
    },
    commentedContentAppLabel: {
      type: String,
      required: true
    },
    commentedContentModel: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      default: 'floating'
    }
  },
  data() {
    return {
      commentHtml: ''
    }
  },
  computed: {
    ...mapState('comments', ['isSavingComment']),
    quillOptions() {
      return {
        theme: 'snow',
        modules: {
          toolbar: null,
          clipboard: {
            matchVisual: false
          }
        },
        placeholder: 'Ingresa tu comentario'
      }
    },
    quill() {
      return this.$refs.quillEditor.quill
    },
    buttonClass() {
      return this.mode === 'floating' ? 'comment-button' : 'verbose-comment-button'
    },
    containerClass() {
      return `container is-widescreen mode-${this.mode}${this.mode === 'inline' ? ' white-box' : ''}`
    },
    dataIsValid() {
      if (this.commentHtml) {
        return true
      }

      return false
    }
  },
  methods: {
    async submitComment(event) {
      if (!this.isSavingComment && !this.isQuillEmpty(this.quill)) {
        // For some reason the .prevent is not working with the quill editor, for that reason,
        // we are forced to remove the last character if the user presses enter.
        if (event.code === 'Enter') {
          this.removeLastCharacterFromQuillEditor()
        }

        await this.$store.dispatch(
          'comments/addComment',
          {
            content: JSON.stringify({ delta: this.quill.getContents(), html: this.commentHtml }),
            commentedContentAppLabel: this.commentedContentAppLabel,
            commentedContentModel: this.commentedContentModel,
            commentedContentIdentifier: this.commentedContentIdentifier
          }
        )

        this.quill.setText('')
      }
    },
    removeLastCharacterFromQuillEditor() {
      const cursorIndex = this.quill.getSelection().index
      const originalTextValue = this.quill.getText()
      const textValue = originalTextValue.slice(0, cursorIndex - 1)
        + originalTextValue.slice(cursorIndex, originalTextValue.length)

      this.quill.setText(textValue)
      this.quill.setSelection(cursorIndex - 1, 0)
    },
    isQuillEmpty(quill) {
      if ((quill.getContents()['ops'] || []).length !== 1) { return false }

      return quill.getText().trim().length === 0
    },
    onQuillEditorChange(value) {
      this.commentHtml = value.html
    }
  }
}
</script>

<style lang="scss" scoped>
.mode-floating {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  max-width: 718px;

  @include respond(mobile) {
    max-width: calc(100% - 32px);
  }

  .add-comment-container {
    background: #FFFFFF;
    border: 4px solid #fff;
    box-shadow: 0px 2px 4px rgba(81, 107, 152, 0.16);
    border-radius: 30px;

    @include respond(mobile) {
      background: #ebebeb;
    }

    .border {
      display: flex;
      padding: 13px 20px;
      margin: 0;

      .button-container {
        span {
          display: none;
        }
      }

      .text {
        min-height: 26px;
      }
    }
  }
}

.mode-inline {
  margin-bottom: 30px;

  .add-comment-container {

    .border {
      @include respond(mobile-up) {
        padding: 28px 37px;
      }

      @include respond(mobile) {
        padding: 22px 26px;
      }

      .button-container {
        display: flex;
        justify-content: flex-end;
      }
    }

    .text {
      border: 2px solid #e1e1e1;
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 5px;
    }
  }
}

.add-comment-container {
  .border {
    margin: 4px;
    padding: 14px 20px;

    border-radius: 26px;

    .icon {
      display: none;

      width: 32px;
      height: 26px;
      margin-right: 12px;

      background: url("../../assets/comments/comment-smile.svg") center no-repeat;
    }

    .text {
      width: 100%;
      min-height: 24px;
      max-height: 100px;
    }

    .button-container {
      display: flex;
      align-items: center;

      .comment-button {
        width: 26px;
        height: 24px;
        margin-left: 12px;
        padding: 0px;

        border: none;
        background: url("../../assets/comments/comment-send.svg") center no-repeat;
        transition: $buttonBackgroundTransition;

        &.is-loading {
          background: none;
        }
      }

      .verbose-comment-button {
        background: $blue;
        color: #fff;
        line-height: 30px;
        height: 30px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 600;
        border: none;
        transition: $buttonBackgroundTransition;

        &:hover {
          background: darken($blue, 6);
        }
      }
    }

  }
}

.mode-inline {
  ::v-deep .ql-container.ql-snow {
    .ql-editor.ql-blank::before {
      font-size: 14px;
      color: #808080;
    }
  }
}

::v-deep .ql-container.ql-snow {
  border: none;
  font-family: Nunito Sans;

  .ql-editor {
    max-height: 100px;
    padding: 3px;

    font-size: 14px;
    color: rgba(56, 56, 56, 0.6);
  }

  .ql-editor.ql-blank::before {
    left: 2px;
    font-style: normal;
  }
}
</style>
