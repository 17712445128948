<template>
  <div>
    <b-loading
      v-if="loading"
      :is-full-page="true"
      :can-cancel="false"
      :active="true"
    />
    <template v-else>
      <div class="container is-widescreen page-bottom-padding">
        <div class="wrapper">
          <div class="columns is-centered">
            <div class="column standard-column">
              <div class="thread-container">
                <thread-heading :thread="singleThreadData" />
                <div class="thread-content-container">
                  <h1 class="thread-title">
                    {{ singleThreadData.title }}
                  </h1>
                  <!-- eslint-disable vue/no-v-html -->
                  <div
                    class="thread-content"
                    v-html="singleThreadData.content"
                  />
                  <!-- eslint-enable vue/no-v-html -->
                  <div
                    v-if="singleThreadData.threadMedia.length > 0"
                    class="thread-media"
                  >
                    <div
                      v-for="mediaItem in singleThreadData.threadMedia"
                      :key="mediaItem.id"
                      class="interactive-content-media"
                    >
                      <media
                        :model="mediaItem"
                        :media-width="688"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <add-comment-bar
                v-if="isParticipant || !currentProjectStaffUserIsObserver"
                :commented-content-identifier="singleThreadData.identifier"
                :commented-content-app-label="'projects'"
                :commented-content-model="'thread'"
                :mode="'inline'"
              />
              <div class="comments-container">
                <template v-if="commentsData && commentsData.length > 0">
                  <h2 class="section-title">
                    {{ commentsData.length }} respuestas
                  </h2>
                  <comment
                    v-for="(comment) in commentsData"
                    :key="comment.id"
                    :comment="comment"
                    :display-participant-tags="displayParticipantTags"
                  />
                </template>
                <template v-else>
                  <h2 class="section-title">
                    Respuestas
                  </h2>
                  <div
                    class="no-comments"
                  >
                    Aún no hay respuestas
                  </div>
                </templatE>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AddCommentBar from '@/components/comments/AddCommentBar.vue'
import Comment from '@/components/interactions/Comment.vue'
import Media from '../Media.vue'
import ThreadHeading from './ThreadHeading.vue'

export default {
  name: 'ThreadDetail',
  components: {
    AddCommentBar,
    Comment,
    Media,
    ThreadHeading
  },
  props: {
    displayParticipantTags: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters('Projects', ['currentProjectStaffUserIsObserver']),
    ...mapState('comments', ['commentsData']),
    ...mapState('forum', ['singleThreadData']),
    ...mapState('participationProject', ['projectData']),
    author() {
      return this.singleThreadData.author
    },
    userColor() {
      const hash = this.hashCode(`${this.author.firstName} ${this.author.lastName}`)
      const color = this.intToRGB(hash)

      return color
    },
    userFontColor() {
      return `#${this.userColor}cc`
    },
    userBackgroundColor() {
      return `#${this.userColor}22`
    },
    userFullName() {
      return `${this.author.firstName} ${this.author.lastName}`
    },
    userInitials() {
      const { firstName, lastName } = this.author

      const firstNameInitial = firstName && firstName !== '' ? firstName.charAt(0) : 'N'
      const lastNameInitial = lastName && lastName !== '' ? lastName.charAt(0) : 'N'

      return `${firstNameInitial}${lastNameInitial}`
    }
  },
  async created() {
    // Load thread data
    let success = await this.$store.dispatch(
      'forum/getSingleThreadData',
      {
        projectIdentifier: this.$route.params.projectIdentifier,
        threadIdentifier: this.$route.params.threadIdentifier
      }
    )

    if (success) {
      // Load comments data
      success = await this.$store.dispatch(
        'comments/getCommentsData',
        {
          commentedContentAppLabel: 'projects',
          commentedContentModel: 'thread',
          commentedContentIdentifier: this.singleThreadData.identifier
        }
      )

      if (success) {
        if (this.displayParticipantTags) {
          success = await this.$store.dispatch(
            'staffParticipants/getParticipantsData',
            {
              projectIdentifier: this.$route.params.projectIdentifier
            }
          )

          if (success) this.loading = false
        } else {
          this.loading = false
        }
      }
    }
  },
  methods: {
    hashCode(str) {
      let hash = 0
      for (let i = 0; i < str.length; i += 1) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash) // eslint-disable-line no-bitwise
      }
      return hash
    },
    intToRGB(i) {
      const c = (i & 0x00FFFFFF) // eslint-disable-line no-bitwise
        .toString(16)
        .toUpperCase()

      return '00000'.substring(0, 6 - c.length) + c
    }
  }
}
</script>

<style lang="scss" scoped>
.thread-container {
  background: #fff;
  margin-bottom: 18px;
  border: 1px solid $gray-light;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(200, 200, 200, 0.24);
  border-radius: 6px;
  padding: 14px;

  @include respond(mobile-up) {
    .thread-title {
      font-size: 23px;
      margin-bottom: 20px;
    }

    .thread-content-container {
      padding: 0 35px 19px 40px;
    }
  }

  @include respond(mobile) {
    .thread-title {
      font-size: 16px;
      line-height: 27px;
      margin-bottom: 12px;
    }

    .thread-content-container {
      padding: 0 16px 19px 0;
    }
  }

  .thread-title {
    text-align: left;
    padding: 0;
    font-weight: 600;
  }

  .thread-heading {
    display: flex;
  }

  &.border {
    border-bottom: 1px solid $gray-light;
  }

  .thread-content {
    text-align: left;
    font-size: 15px;
    line-height: 24px;
    color: #606875;
  }

  .comments {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: $blue;

    &:hover {
      text-decoration: underline;
    }

    .icon {
      width: 24px;
      height: 24px;
      background: url("../../assets/comments/comment-blue.svg") center no-repeat
    }
  }

  .thread-media {
    margin-top: 20px;
  }
}

.comments-container {
  text-align: left;
}

.no-comments {
  padding-top: 14px;
  border-top: 1px solid $gray-light;
}
</style>
