<template>
  <router-link
    v-slot="{ href, navigate }"
    :to="to"
  >
    <a
      class="action-icon-button"
      :href="href"
      :title="title"
      @click="navigate"
    >
      <span :class="['action-icon', `icon-${icon}`]" />
      <slot />
    </a>
  </router-link>
</template>

<script>
export default {
  name: 'ActionIconButton',
  props: {
    to: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.action-icon-button {
  display: flex;
  width: 44px;
  height: 44px;
  border-radius: 6px;
  background: #E7F0FF;
  justify-content: center;
  align-items: center;
  transition: background 0.07s ease;
  position: relative;

  &:hover {
    background: darken(#E7F0FF, 2.5);
  }
}

.action-icon {
  display: block;
  background-size: contain;
  background-repeat: no-repeat;

  &.icon-bar-chart {
    width: 20px;
    height: 20px;
    background-image: url('../assets/icons/bar-chart.svg');
  }

  &.icon-pencil {
    width: 17px;
    height: 17px;
    background-image: url('../assets/icons/pencil.svg');
  }

  &.icon-responses {
    width: 20px;
    height: 20px;
    background-image: url('../assets/icons/responses.svg');
  }
}
</style>
