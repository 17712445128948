<template>
  <div class="comment-container message-format-container with-borders">
    <avatar :user-data="comment.author" />
    <div class="message-format-right">
      <div class="message-format-header">
        <div class="message-format-name">
          {{ userFullName }}
        </div>
        <div
          v-if="isFromStaffUser"
          class="message-format-user-attribute"
        >
          Moderador
        </div>
        <div
          class="message-format-time"
        >
          {{ formattedCommentTime }}
        </div>
      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="message-format-content"
        v-html="comment.content"
      />
      <!-- eslint-enable vue/no-v-html -->
      <div class="message-format-footer">
        <tag-list
          v-if="displayParticipantTags && participantTags && participantTags.length > 0"
          :tag-data="participantTags"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'timeago.js'
import { mapState } from 'vuex'

import Avatar from '../Avatar.vue'
import TagList from '../TagList.vue'

export default {
  name: 'Comment',
  components: {
    Avatar,
    TagList
  },
  props: {
    comment: {
      type: Object,
      default: null
    },
    displayParticipantTags: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('Projects', ['projectData']),
    userFullName() {
      return `${this.comment.author.firstName} ${this.comment.author.lastName}`
    },
    formattedCommentTime() {
      return format(this.comment.created, 'es')
    },
    isFromStaffUser() {
      return this.projectData && this.projectData.staffUsers.some(
        (su) => su.userIdentifier === this.comment.author.identifier
      )
    },
    participantTags() {
      if (this.displayParticipantTags && !this.loading) {
        const participantData = this.$store.getters['staffParticipants/participantDataByUserIdentifier'](
          this.comment.author.identifier
        )

        if (participantData) return participantData.tags

        return null
      }

      return null
    }
  }
}
</script>
