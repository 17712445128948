<template>
  <div class="general-container">
    <the-navbar
      :centered="true"
      :return-link="returnLink"
    />
    <b-loading
      v-if="loadingInitialData"
      :is-full-page="true"
      :active="true"
    />
    <template v-else>
      <div class="header project-header extra-top-padding">
        <div class="wide-column header-title">
          <div class="header-content">
            <div class="pre-title">
              <h3>
                {{ $t('global.project') }}
              </h3>
            </div>
            <div class="title">
              <h1>{{ projectData.name }}</h1>
            </div>
            <div class="sub-title">
              <h2 v-if="projectParticipantCount > 0">
                <span>{{ projectParticipantCount }}</span> {{ $t('global.participants').toLowerCase() }}
              </h2>
              <h2 v-else>
                {{ $t('project_dashboard.project_has_no_participants') }}
              </h2>
            </div>
          </div>
          <div
            v-if="showDownload"
            class="header-actions"
          >
            <export-status-indicator v-if="processingExports" />
            <div class="dropdown-container">
              <div class="dropdown dropdown-menu-animation download is-bottom-left is-mobile-modal">
                <div
                  v-click-outside="hideDownloadMenu"
                  role="button"
                  aria-haspopup="true"
                  class="dropdown-trigger"
                  @click="toggleDownloadMenu()"
                >
                  <b-button class="action-button">
                    <download-icon />
                  </b-button>
                </div>
                <div
                  v-if="showDownloadMenu"
                  class="dropdown-menu"
                >
                  <div
                    role="list"
                    class="dropdown-content"
                  >
                    <a
                      role="listitem"
                      tabindex="0"
                      :class="{ 'dropdown-item': true, disabled: processingExports }"
                      @click="downloadXLSX"
                    >
                      <div class="download-icon xlsx">
                        <img
                          src="../assets/project/icon-excel.svg"
                          :alt="$t('global.download')"
                        >
                      </div>
                      <div class="text">{{ $t('global.spreadsheet') }} (.xlsx)</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tabs-container">
          <div class="wide-column tabs">
            <ul>
              <li
                v-for="section in sections"
                :key="section.name"
                :class="[activeSection === section.name ? 'is-active' : '']"
              >
                <a @click="navigateToSection(section.name)">{{ section.title }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="dashboard-content">
        <dashboard-summary v-if="summaryActive" />
        <div
          v-else-if="forumActive && !threadIdentifier"
          class="container is-widescreen page-bottom-padding"
        >
          <div class="wrapper">
            <div class="columns is-centered">
              <div class="column standard-column">
                <thread-listing
                  :detail-route-name="'project-dashboard-forum-thread'"
                />
              </div>
            </div>
          </div>
        </div>
        <thread-detail
          v-else
          :display-participant-tags="true"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapGetters, mapState } from 'vuex'

import DashboardSummary from '@/components/project-dashboard/DashboardSummary'
import DownloadIcon from '@/components/icons/DownloadIcon.vue'
import ExportStatusIndicator from '@/components/exports/ExportStatusIndicator.vue'
import TheNavbar from '@/components/TheNavbar.vue'
import ThreadDetail from '@/components/forum/ThreadDetail.vue'
import ThreadListing from '@/components/forum/ThreadListing.vue'
import dashboardSections from '@/components/project-dashboard/dashboardSections'
import vue from '../main'

export default {
  name: 'Resumen',
  components: {
    DashboardSummary,
    DownloadIcon,
    ExportStatusIndicator,
    TheNavbar,
    ThreadDetail,
    ThreadListing
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      showDownloadMenu: false,
      loadingInitialData: true
    }
  },
  computed: {
    ...mapGetters('dataExports', ['processingExports']),
    ...mapGetters(
      'Projects',
      ['isCommunityProject', 'currentProjectStaffUserIsObserver', 'projectParticipantCount']
    ),
    ...mapState('Projects', ['projectData']),
    activeSection() {
      return this.$route.meta.activeSection
    },
    returnLink() {
      if (this.projectData) {
        return {
          title: vue.$t('global.projects'),
          link: {
            name: 'proyectos',
            params: {
              identifier: this.projectData.identifier
            }
          }
        }
      }

      return null
    },
    summaryActive() {
      return this.activeSection === dashboardSections.summary
    },
    forumActive() {
      return this.activeSection === dashboardSections.forum
    },
    sections() {
      return [
        {
          name: dashboardSections.summary,
          title: vue.$t('global.summary')
        },
        {
          name: dashboardSections.forum,
          title: vue.$t('global.forum')
        }
      ]
    },
    threadIdentifier() {
      return this.$route.params.threadIdentifier
    },
    showDownload() {
      return !this.currentProjectStaffUserIsObserver
    }
  },
  async created() {
    await this.$store.dispatch(
      'Projects/getProjectData',
      { projectIdentifier: this.$route.params.projectIdentifier, edit: false }
    )

    if (!this.isCommunityProject) {
      return
    }
    this.loadingInitialData = false
  },
  methods: {
    downloadXLSX() {
      if (this.processingExports) return

      this.$store.dispatch(
        'dataExports/startDataExport',
        { projectIdentifier: this.projectData.identifier, fileType: 'xlsx' }
      )

      this.hideDownloadMenu()
    },
    hideDownloadMenu() {
      if (this.showDownloadMenu) this.showDownloadMenu = false
    },
    toggleDownloadMenu() {
      this.showDownloadMenu = !this.showDownloadMenu
    },
    navigateToSection(sectionName) {
      if (this.activeSection !== sectionName) {
        this.$router.push({
          name: `project-dashboard-${sectionName}`,
          params: {
            projectIdentifier: this.$route.params.projectIdentifier
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/scss/project_dashboard.scss';
</style>
