<template>
  <div
    class="activity-item"
  >
    <div class="activity-content">
      <div class="activity-type">
        Cuestionario Abierto
      </div>
      <div class="activity-name-and-progress">
        <h3>{{ activity.name }}</h3>
        <div class="activity-progress">
          <b-progress
            :type="'is-primary'"
            :size="'small'"
            :value="activity.completedParticipations"
            :max="projectParticipantCount"
            :show-value="false"
            :class="'is-small is-info'"
          />
          <div class="progress-text">
            {{ progressMessage }}
          </div>
        </div>
      </div>
      <div class="activity-bottom-info">
        <div
          v-if="displayDate"
          class="activity-schedule"
        >
          {{ displayDate }}
        </div>
        <div class="activity-last-interactions">
          <div
            v-if="activity.lastResponseDate"
            class="activity-last-response"
          >
            <b>Última respuesta:</b>
            <span class="activity-last-response-date">
              {{ formattedLastResponseDate }}
            </span>
          </div>
          <div
            v-if="activity.lastCommentDate"
            class="activity-last-comment"
          >
            <b>Último comentario:</b>
            <span class="activity-last-comment-date">
              {{ formattedLastCommentDate }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="activity-actions">
      <action-icon-button
        v-if="displayEditLink && !currentProjectStaffUserIsObserver"
        :to="editLink"
        :title="'Editar'"
        :icon="'pencil'"
      />
      <action-icon-button
        :to="interactionsLink"
        :title="'Respuestas'"
        :icon="'responses'"
      >
        <div
          v-if="hasComments"
          class="activity-comments-count"
        >
          <span>{{ activity.commentsCount }}</span>
        </div>
      </action-icon-button>
      <action-icon-button
        v-if="!currentProjectStaffUserIsObserver"
        :to="reportLink"
        :title="'Reporte'"
        :icon="'bar-chart'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { format } from 'timeago.js'

import ActionIconButton from '@/components/ActionIconButton.vue'

import { formatActivityDates } from '../../../dates'

export default {
  name: 'ActivityItem',
  components: {
    ActionIconButton
  },
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('Projects', ['projectNotPublished', 'projectParticipantCount', 'currentProjectStaffUserIsObserver']),
    ...mapState('Projects', ['projectData']),
    displayDate() {
      const { participationEnabledFrom, participationEnabledTo } = this.activity

      return formatActivityDates(participationEnabledFrom, participationEnabledTo)
    },
    displayEditLink() {
      return this.projectNotPublished || !this.activity.usersCanParticipate
    },
    editLink() {
      return {
        name: 'edit-activity',
        params: { projectIdentifier: this.projectData.identifier, activityIdentifier: this.activity.identifier }
      }
    },
    formattedLastResponseDate() {
      const { lastResponseDate } = this.activity

      if (!lastResponseDate) {
        return ''
      }

      return format(lastResponseDate, 'es')
    },
    formattedLastCommentDate() {
      const { lastCommentDate } = this.activity

      if (!lastCommentDate) {
        return ''
      }

      return format(lastCommentDate, 'es')
    },
    interactionsLink() {
      return {
        name: 'project-interactions-filtered',
        params: { projectIdentifier: this.projectData.identifier, activityIdentifier: this.activity.identifier }
      }
    },
    reportLink() {
      return {
        name: 'activity-dashboard',
        params: { projectIdentifier: this.projectData.identifier, activityIdentifier: this.activity.identifier }
      }
    },
    progressMessage() {
      return (
        `${this.activity.completedParticipations
        }/${
          this.projectParticipantCount
        } respuestas`
      )
    },
    hasComments() {
      return this.activity.commentsCount > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-item {
  color: #000;
  display: block;
  background: #fff;
  box-shadow: 0px 4px 6px 0px #C8C8C83D;
  border: 1px solid #E1E1E1;
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 15px;
  user-select: none;
  min-height: 104px;
}

.activity-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-right: 16px;

  @include respond(mobile) {
    padding-right: 0px;
  }

  .activity-type {
    margin-bottom: 10px;

    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }

  .activity-name-and-progress {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-right: 14px;

    .activity-progress {
      width: 250px;
      position: relative;

      ::v-deep .progress {
        height: 8px;
        margin-top: 12px;
      }

      .progress-text {
        position: absolute;
        right: 0px;
        top: 24px;
        font-size: 14px;
        color: #121212;
        user-select: none;
      }
    }
  }

  .activity-bottom-info {
    display: flex;

    .activity-schedule {
      margin-top: 1px;
      padding-top: 1px;
    }
  }

  ::v-deep .activity-last-interactions {
    display: flex;
    margin-left: 10px;

    font-size: 15px;
    color: #121212;

    .activity-last-response, .activity-last-comment {
      margin-right: 10px;
    }

  }
}

.activity-item-heading {
  display: flex;
  justify-content: space-between;
}

.activity-actions {
  display: flex;
  align-items: center;

  a:not(:first-child) {
    margin-left: 12px;
  }

  .activity-comments-count {
    background-color: #0a6cff;
    position: absolute;
    border-radius: 12px;
    height: 20px;
    width: 20px;
    color: white;
    font-size: 12px;
    text-align: center;
    padding-top: 1px;
    top: -7px;
    right: -7px;
  }
}

h3 {
  font-size: 20px;
  font-weight: 400;
  position: relative;
  top: -3px;
}

@include respond(mobile-up) {
  .activity-item {
    display: flex;
    justify-content: space-between;
  }
}

@include respond(mobile) {
  .activity-content {
    margin-bottom: 18px;

    h3 {
      margin-bottom: 12px;
    }
  }
}
</style>
