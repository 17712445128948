<template>
  <div class="export-status-indicator">
    <spinner />
    <span>Generando archivo{{ Object.keys(pendingExports).length > 1 ? 's' : '' }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Spinner from '../Spinner.vue'

export default {
  name: 'ExportsMenu',
  components: {
    Spinner
  },
  computed: {
    ...mapGetters('dataExports', ['pendingExports'])
  }
}
</script>

<style lang="scss" scoped>
.export-status-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;

  .spinner {
    margin-right: 16px;
  }

  @include respond(mobile-tablet) {
    span {
      display: none;
    }

    .spinner {
      margin-right: 0;
    }
  }
}
</style>
