import { render, staticRenderFns } from "./AddCommentBar.vue?vue&type=template&id=979c25de&scoped=true&"
import script from "./AddCommentBar.vue?vue&type=script&lang=js&"
export * from "./AddCommentBar.vue?vue&type=script&lang=js&"
import style0 from "./AddCommentBar.vue?vue&type=style&index=0&id=979c25de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "979c25de",
  null
  
)

export default component.exports