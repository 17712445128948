<template>
  <b-loading
    v-if="false"
    :is-full-page="true"
    :can-cancel="false"
    :active="true"
  />
  <div
    v-else
    class="activity-listing"
  >
    <div class="wide-column">
      <custom-link-button
        class="main-button"
        button-text="Ver respuestas y comentarios"
        icon-image="project/icon-responses.svg"
        :to="interactionsLink"
        :display-arrow="true"
      />
    </div>
    <div class="wide-column">
      <div class="title">
        <h2 class="project-dashboard-title">
          Actividades
        </h2>
      </div>
      <activity-listing :activities="projectData.activities" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ActivityListing from './activity-listing/ActivityListing.vue'
import CustomLinkButton from '../CustomLinkButton.vue'

export default {
  name: 'DashboardSummary',
  components: {
    ActivityListing,
    CustomLinkButton
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState('Projects', ['projectData']),
    interactionsLink() {
      return { name: 'project-interactions', params: { projectIdentifier: this.projectData.identifier } }
    }
  }
}
</script>

<style lang="scss" scoped>
.main-button {
  margin-bottom: 20px;

  @include respond(mobile) {
    margin-bottom: 16px;
  }
}
</style>
